<template>
    <v-carousel cycle interval="10000" :show-arrows="['xs', 'sm'].includes($vuetify.breakpoint.name)" hide-delimiters id="carousel" height="auto">
        <v-carousel-item v-for="(item, i) in items" :key="i" style="">
            <v-card tile>
                <v-img 
                    :src="checkImagePath(item.image_path)" 
                    lazy-src="@/assets/loading_img.jpg"
                    position="top"
                    class="ma-0 white--text align-end"
                    height="800"
                >   
                    <v-row class="pa-0"> <!-- Use v-row to create a row -->
                        <v-col v-for="(sub_image, index) in item.event_activity_images" :key="index" cols="12" sm="12" md="12" lg="12"> <!-- Use v-col to create columns -->
                            <v-img 
                                :src="checkImagePath(sub_image.image_path)" 
                                :height="imageHeight()"
                                lazy-src="@/assets/loading_img.jpg"
                                width="100%"
                                style="margin-bottom:30%;"
                                contain
                            />
                        </v-col>
                    </v-row>
                    <v-card color="#00000080">
                        <p class="ma-0 pa-2" :style="`font-size:${font_size()}px;`">{{ item.description }}</p>
                    </v-card>
                </v-img>
            </v-card>
        </v-carousel-item>
    </v-carousel>    
</template>
<script>
import SharedFunctionsComponent from '@/components/shared/SharedFunctionsComponent.vue'
import { mapGetters } from 'vuex';
export default {
    mixins: [SharedFunctionsComponent],
    data() {
        return {
            items: []
        };
    },
    computed: {
        ...mapGetters([
            'GET_EVENT_ID'
        ])
    },
    created() {
    },
    async mounted() {
        await this.getActivities()
    },
    methods: {
        async getActivities(){
            let payload = {
                url: "getEventActivities",
                event_id: this.GET_EVENT_ID
            }
            await this.$store.dispatch('urlGet', payload).then((res)=>{
                this.items = res.data
            })
        },
        font_size() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return "11"
                case 'sm': return "11"
                case 'md': return "15"
                case 'lg': return "15"
                case 'xl': return "15"
            }
        },
        imageHeight(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '400'
                case 'sm': return '400'
                case 'md': return '300'
                case 'lg': return '300'
                case 'xl': return '300'
            }
        },
        pos(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '38%'
                case 'sm': return '45%'
                case 'md': return '60%'
                case 'lg': return '60%'
                case 'xl': return '60%'
            }
        },
    },
    watch: {
       
    },
};
</script>
<style scoped>

</style>
