<template>
    <v-main style="background-color: black;">
        <v-container fluid>
			<v-row class="system_toolbar system_toolbar_color d-flex justify-content-between">
				<v-tabs background-color="transparent" hide-slider dark style="margin-left: 10px;">
                    <v-img src="@/assets/ingcoph_logo_alt.png" max-height="50" max-width="190" style="margin-left: -5px;"/>
				</v-tabs>
			</v-row>
            <v-row>
                <v-col cols="12" style="color:#f69f1a; font-family: 'Evogria';" class="text-center">
                    <v-icon color="#f69f1a" size="100">
                        mdi-emoticon-sad-outline
                    </v-icon>
                    <h1>404 Page Not Found</h1>
                    <p>
                        The Page you are looking for doesn't exist or an error occured.
                    </p>
                </v-col>
            </v-row>
		</v-container>
    </v-main>
</template>

<script>
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            
        };
    },
    computed: {
        ...mapGetters([]),
      
    },
    mounted() {

	},
    created() {
        document.title = 'Not Found';
    },
    methods: {
    },
    watch:{
    }
};
</script>

<style scoped>
    .transparent-btn { background-color: transparent; border: none; }
    .black-font { color: black !important; }
    .slide-enter-active, .slide-leave-active {
        transition: transform 0.5s;
    }
    #ingco-logo-img{
        height: 40px;
    }
    .slide-enter, .slide-leave-to {
        transform: translateX(-6%);
    }
    body {
        overflow: hidden;
    }
    .flex-container {
        display: flex;
        align-items: center;
    }
    .flex-container span {
        margin-right: 10px; 
    }
    .adaptive-text {
        font-size: 14px;
        justify-content: center;
    }
    @media (max-width: 600px) {
        .adaptive-text {
        font-size: 12px; 
        }
    }
    .responsive-img {
        height: 100%;
        width: 100%;
        display: block;
        margin: 0 auto;
    }
    @media (max-width: 600px) {
        .responsive-img {
            height: 100%;
   
            width: 100%;
            display: block;
            margin: 0 auto;
        }
    }
    
    .fillup-button-textsize{
        font-size: 14px;
        justify-content: center;
    }

    .carousel-name-text-font {
        font-size: 50px; 
    }

    .carousel-desc-name-text-font {
        font-size: 40px; 
    }
    @media (max-width: 600px) {
        .fillup-button-textsize {
            font-size: 10px; 
        }

        .carousel-name-text-font {
            font-size: 30px; 
        }
         .carousel-desc-name-text-font {
            font-size: 20px; 
        }
    }
    .small-header {
        padding-top: 1px; /* Adjust as needed */
        padding-bottom: 1px; /* Adjust as needed */
    }
    body {
    background-color: black;
    }
       .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height:auto; /* Adjust this value as needed */

    }
    ::v-deep .white-text-field input {
        color: white !important;
      }
</style>

