import repository from '@/api/modules/masterdata/masterdata'

export default {
    state: {
        new_master_data: false,
        dialog: false,
        action: '',
        dialog_view: {},
		form_settings:[],
        current_user: '',
        voted: {},
        remaining_guests: [],
        remind_guest: 0,
        preferences: [],
        event_id: null,
    },
    getters: {
        GET_NEW_MASTER_DATA:(state) => state.new_master_data,
        DIALOGS:(state) => state.dialog, 
        ACTION: state => state.action,
        GET_DIALOG_VIEW: (state) => state.dialog_view,
        GET_FORM_SETTINGS: (state) => state.form_settings,
        GET_CURRENT_USER: (state) => state.current_user,
        GET_VOTED: (state) => state.voted,
        GET_REMAINING_GUEST: (state) => state.remaining_guests,
        GET_REMIND_GUEST: (state) => state.remind_guest,
        GET_PREFERENCES: (state) => state.preferences,
        GET_EVENT_ID: (state) => state.event_id,
    },
    actions:{
        urlGet({commit}, payload){
            payload.event_id = this.getters.GET_EVENT_ID
            return new Promise((resolve, reject)=>{
                repository.urlGet(payload).then((response)=>{
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        urlPost({commit}, payload){
            payload.event_id = this.getters.GET_EVENT_ID
            return new Promise((resolve, reject)=>{
                repository.urlPost(payload).then((response)=>{
                    // commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        urlPut({commit}, payload){
            payload.event_id = this.getters.GET_EVENT_ID
            return new Promise((resolve, reject)=>{
                repository.urlPut(payload).then((response)=>{
                    // commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        urlDelete({commit}, payload){
            payload.event_id = this.getters.GET_EVENT_ID
            return new Promise((resolve, reject)=>{
                repository.urlDelete(payload).then((response)=>{
                    commit('NEW_MASTER_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
    },
    mutations:{
        NEW_MASTER_DATA:(state, data) => {state.new_master_data = data},
        DIALOG:(state, data) => {state.dialog = data},
        ACTION:(state, data) => {state.action = data},
        DIALOG_VIEW:(state, data) => {state.dialog_view = data},
        FORM_SETTINGS:(state, data) => {state.form_settings = data},
        CURRENT_USER:(state, data) => {state.current_user = data},
        VOTED:(state, data) => {state.voted = data},
        REMAINING_GUEST:(state, data) => {state.remaining_guests = data},
        REMIND_GUEST:(state, data) => {state.remind_guest = data},
        PREFERENCES:(state, data) => {state.preferences = data},
        EVENT_ID:(state, data) => {state.event_id = data},
    }
}
