import Vue from "vue";
import Vuex from "vuex";
import masterdata from "@/store/modules/masterdata/masterdata"

Vue.use(Vuex)

export default new Vuex.Store({
  strict: false,
  modules: {
    masterdata,
  },
});

