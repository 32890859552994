import { render, staticRenderFns } from "./NotFoundComponent.vue?vue&type=template&id=f626eb2a&scoped=true"
import script from "./NotFoundComponent.vue?vue&type=script&lang=js"
export * from "./NotFoundComponent.vue?vue&type=script&lang=js"
import style0 from "./NotFoundComponent.vue?vue&type=style&index=0&id=f626eb2a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f626eb2a",
  null
  
)

export default component.exports