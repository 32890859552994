<template>
	<v-main>
		<v-container fluid>
			<div v-show="showTab">
				<v-row class="system_toolbar system_toolbar_color d-flex justify-content-between">
					<v-tabs background-color="transparent" hide-slider dark style="margin-left: 10px;">
						<v-img
							:src="checkImagePathLogo(GET_PREFERENCES.find(x => x.parameter == 'system_toolbar_image')?.value ?? null)"
							max-height="50"
							max-width="190"
							style="margin-left: -5px"
							class="logo-img"
						></v-img>					
						<v-menu v-model="menuOpen" :absolute="false" offset-y class="system_toolbar_font_color_main system_font_family_primary" max-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-tab v-show="showTab" v-bind="attrs" v-on="on" @click="toggleMenu" color="transparent" dark plain class="system_toolbar_font_color_main underline-animation-black system_font_family_primary" style=" margin-left: auto;">
									<svg xmlns="http://www.w3.org/2000/svg" width="1em" viewBox="0 0 24 24">
										<g fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="2">
										<path :d="!menuOpen ? 'M5 5L19 5' : 'M5 5L19 19'" />
										<path :d="!menuOpen ? 'M5 12H19' : 'M12 12H12'" />
										<path :d="!menuOpen ? 'M5 19L19 19' : 'M5 19L19 5'" />
										</g>
									</svg>
								</v-tab>
							</template>
							<div class="border-dotted" style="width: auto">
								<v-card style="width: 300px; height: auto;">
									<v-list  class="border-dotted align-center" color="black">
										<v-divider :thickness="50"></v-divider>
										<span class="system_font_family_primary system_font_color_secondary_log_out" style="font-size: 10px; padding-left: 10px;"> Welcome: &nbsp; {{ employeeName }} &nbsp; </span>
										<v-btn small plain @click="redirectToLandingPage" color="orange">
											<v-list-item-title class="system_font_family_primary white--text" style="font-size: 11px;  padding-left: 20px;">Log Out</v-list-item-title>
										</v-btn>
										<v-divider :thickness="50"></v-divider>
									</v-list>
								</v-card>
							</div>
						</v-menu>
					</v-tabs>
				</v-row>
			</div>
			<v-row v-if="isDesktop" class="system_toolbar system_toolbar_color d-flex w-auto justify-content-between ">
				<div>
					<v-tabs background-color="transparent" hide-slider dark style="margin-left: 10px;">
						<img :src="checkImagePathLogo(GET_PREFERENCES.find(x => x.parameter == 'system_toolbar_image')?.value ?? null)" style="margin-left: -5px;" >
					</v-tabs>
				</div>
				<div v-if="isDesktop" style="display: flex; margin-right: 1%">
					<span class=" system_toolbar_font_color_main system_font_family_primary" style="font-size: small;"><v-icon size="30" center class="system_toolbar_icon_color">mdi-account-check</v-icon> Welcome: &nbsp; {{ employeeName }} &nbsp; </span>
					<v-btn @click="redirectToLandingPage" small color="transparent" dark class="system_toolbar_font_color_main underline-animation-black system_font_family_primary">
						Log Out
					</v-btn>
				</div>
			</v-row>
		</v-container>
		<v-container fluid class="system_font_family_primary">
			<v-card color="transparent" >
				<v-card-title class="d-flex justify-content-center">
					<span class="text-center system_font_family_primary system_main_label_color" style="font-size: 1.5rem; font-weight: bold;">
						Welcome {{ first_name }}!
					</span>
				</v-card-title>
				<v-card-title style="font-size: 1.3rem; font-weight: bold;" class="d-flex justify-content-center system_main_label_color">
					Guest Info
				</v-card-title>
			</v-card>
			<div>
				<div style="justify-content: center;" class="adaptive-text">
					<v-card-text class="adaptive-text">
						<v-icon class="icon_color_main" size="30" left>mdi-account-check</v-icon><span class="system_secondary_label_color" style="font-size:19px;">Name:</span> &nbsp; <span class="system_guest_information_color" style="font-size:19px;">{{ employeeName }}</span>
						<v-divider :thickness="50"></v-divider>
					</v-card-text>
					<v-card-text class="adaptive-text">
						<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" class="icon_color_main">
							<path fill="currentColor" d="M18 15h-2v2h2m0-6h-2v2h2m2 6h-8v-2h2v-2h-2v-2h2v-2h-2V9h8M10 7H8V5h2m0 6H8V9h2m0 6H8v-2h2m0 6H8v-2h2M6 7H4V5h2m0 6H4V9h2m0 6H4v-2h2m0 6H4v-2h2m6-10V3H2v18h20V7z" />
						</svg> &nbsp;<span class="system_secondary_label_color" style="font-size:19px;">Company:</span> &nbsp; <span class="system_guest_information_color" style="font-size:19px;">{{ company_name }}</span>
						<v-divider :thickness="50"></v-divider>
					</v-card-text>
					<v-card-text class="adaptive-text" v-if="settings.data_grouping == 1">
						<v-icon class="icon_color_main" size="30" left>mdi-ticket-confirmation-outline</v-icon><span class="system_secondary_label_color" style="font-size:19px;">Reference Number:</span> &nbsp; <span class="system_guest_information_color" style="font-size:19px;">{{ referenceNumber }}</span>
						<v-divider :thickness="50"></v-divider>
					</v-card-text>
					 <v-card-text class="adaptive-text">
						<v-icon class="icon_color_main" size="30" left>mdi-chair-rolling</v-icon><span class="system_secondary_label_color" style="font-size:19px;">Table Number:</span> &nbsp; <span class="system_guest_information_color" style="font-size:19px;">{{ tableNumber }}</span>
						<v-divider :thickness="50"></v-divider>
					</v-card-text>
					<!-- <v-card-text class="adaptive-text">
						<v-icon class="icon_color_main" size="30" left>mdi-silverware-variant</v-icon><span style="font-size:19px;">Buffet Table:</span> &nbsp; <span class="system_guest_information_color" style="font-size:19px;">{{ buffetTable }}</span>
						<v-divider :thickness="50"></v-divider>
					</v-card-text> -->
					<v-card-text class="adaptive-text" >
						<v-icon class="icon_color_main" size="30" left>mdi-ticket-confirmation</v-icon> <span class="system_secondary_label_color" style="font-size:19px;">Ticket: ({{ticket_num_length}}) </span> &nbsp; 
							<v-text-field
								v-model="ticket_search"
								label="Search"
								color="orange"
								type="number"
								:min="0"
							>
							</v-text-field>
							<v-row no-gutters style="max-height:400px; overflow:auto;">
								<v-col v-for="(ticket_num, i) in ticket_nums" :key="i" cols="6" class="pa-0">
									<span class="system_guest_information_color">
										<v-row no-gutters class="pa-0" v-for="(ticket, index) in ticket_num" :key="index">
											<v-col cols="12" class="text-center" @click="showTicketFull(ticket)">
												{{ ticket }}
											</v-col>
										</v-row>
									</span>
								</v-col>
								<v-dialog v-model="full_screen.dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
									<v-card class="landscape-card pa-2" @click="full_screen.dialog = false" color="black" style="font-size: 80px; overflow:hidden;">
										<span class="system_font_family_primary system_guest_information_color">
											{{ fullScreenTicket }}
										</span>
									</v-card>
								</v-dialog>
							</v-row>
						<v-divider></v-divider>
					</v-card-text>
				</div>
			</div>
		</v-container>
		<v-dialog v-model="with_guests.dialog" persistent width="100%">
			<v-sheet outlined :color="GET_PREFERENCES.find(x => x.parameter == 'system_dialog_border')?.value ?? 'orange'" rounded="">
				<v-card class="overflow-x-hidden">
					<v-card-title class="pb-0 border-bottom">
						<v-row class="m-0">
							<v-col cols="pull-right-10 pa-2">
								<v-btn
									text
									icon
									small
									color="gray"
									class="float-right"
									@click="with_guests.dialog = false"
								>
									<v-icon>mdi-close-circle</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-card-title>
					<v-card-text>
						<v-row no-gutters class="pa-5">
							<v-col cols="12" class="pa-5">
								<v-row no-gutters class="" v-if="GET_REMAINING_GUEST.length > 0 && !list_loading.with_guest">
									<span class="mb-1 mt-2 system_font_family_primary system_guest_information_color" style="font-size: 20px;">
										Select from the following people that are with you
									</span>
									<v-col sm="12" lg="12" style="max-height: 50vh; overflow: auto">
										<span >
											<span v-for="(guest, i) in GET_REMAINING_GUEST" :key="i" >
												<v-checkbox
													@click="selectGuest(guest)"
													:label="guest.full_name"
													color="orange"
													value="orange"
													hide-details
												></v-checkbox>
											</span>
										</span>
									</v-col>
								</v-row>
								<v-row v-else-if="list_loading.with_guest">
									<v-col cols="12" sm="12" class="text-center">
										<v-progress-circular
											indeterminate
											color="orange"
											size="100"
											width="8"
										></v-progress-circular>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-card-text>
					<!-- <v-card-actions
						class="text-center"
						style="border-top: 1px solid #000000"
					> -->
					<v-divider></v-divider>
					<v-row class="ma-1 pa-0">
						<v-col cols="12" class="pa-5 d-flex justify-content-center align-items-center">
							<v-btn
								@click="confirmWithGuests()"
							>
								<span>Submit</span>
							</v-btn>
						</v-col>
					</v-row>
					<!-- </v-card-actions> -->
				</v-card>
			</v-sheet>
		</v-dialog>

	<!-- <v-expansion-panels v-show="showTab"  v-model="showInfoPanelMobile">
		<v-expansion-panel class="small-header">
			<v-expansion-panel-header color="black">
				<span class="text-center expansion_title_color" style="font-size: 1.5rem; font-weight: bold;">
					Welcome! {{ first_name }}
				</span>
			</v-expansion-panel-header>
			<v-expansion-panel-content color="black" style="font-size: 1rem; font-weight: bold;" class="justify-content-between">
				<v-card-title style="justify-content: center;" >
					Attendee Event Information
				</v-card-title>
				
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels> -->
	<v-divider :thickness="50"></v-divider>
	<v-expansion-panels class="system_font_family_primary" v-show="showTab && settings.voting_start_date !== null && settings.star_of_the_night == 1" v-model="showPeoplesChoicePanelMobileMr">
		<v-expansion-panel>
			<v-expansion-panel-header color="black" class="small-header">
				<span class="text-center expansion_title_color" style="font-size: 1.5rem; font-weight: bold;">
					<v-icon color="#67cdda" class="mr-2">mdi-star-crescent</v-icon>MR. Star of the Night
				</span>
			</v-expansion-panel-header>
			<v-expansion-panel-content color="black" style="font-size: 1rem; font-weight: bold;" class="justify-content-start">
				<!-- <v-card-title style="justify-content: center;" v-if="!(settings.voting_start_date !== null && settings.voting_end_date !== null)">
					People's Choice Nomination
				</v-card-title> -->
				<v-card-title style="justify-content: center;">
					People's Choice Nominees
				</v-card-title>
					<!-- <v-card-text class="adaptive-text mt-2" v-if="(nominationTicketMr > 0 && settings.nomination_end_date === null)">
						<v-row no-gutters>
							<v-col class="pa-0" cols="12" sm="12" md="4">
								<v-autocomplete dense
									v-model="selected_mr.company"
									@change="filterEmployeeNamesByCompany(selected_mr.company, 'selected_mr')"
									:items="dropdowns.company_list"
									item-text="name"
									item-value="id"
									label="Select Company Name"
									color="orange"
									clearable
									>
								</v-autocomplete>
							</v-col>
							<v-col class="pa-0" align="center">
								<v-autocomplete
									dense
									label="Select Attendee Name"
									color="orange"
									v-model="selected_mr.nominee"
									:items="employee_names_male"
									@change="autoSelectCompany(selected_mr.nominee, 'selected_mr')"
									clearable
									>
								</v-autocomplete>
							</v-col>	
							<v-col class="pa-0" cols="12" sm="12" md="4">
								<v-btn class="black--text" color="orange" style="width: 100%" dark solo @click="appendName('selected_mr')" small>
									Search
								</v-btn>
							</v-col>
						</v-row>
					</v-card-text> -->
					<!-- <v-card-text class="adaptive-text" v-if="this.nomineeIDMr && (settings.nomination_start_date === null || settings.nomination_end_date === null)">
						<v-icon class="icon_color_main" size="30" left>mdi-account-check</v-icon>Participant Name: &nbsp; <v-spacer></v-spacer>
						<span class="system_guest_information_color" style="padding-left: 12%;"> {{ selected_mr.name }} </span>
						<v-divider :thickness="50"></v-divider>
						<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
							<path fill="currentColor" d="M18 15h-2v2h2m0-6h-2v2h2m2 6h-8v-2h2v-2h-2v-2h2v-2h-2V9h8M10 7H8V5h2m0 6H8V9h2m0 6H8v-2h2m0 6H8v-2h2M6 7H4V5h2m0 6H4V9h2m0 6H4v-2h2m0 6H4v-2h2m6-10V3H2v18h20V7z" />
						</svg>&nbsp;&nbsp;Company: &nbsp; <v-spacer></v-spacer>
						<span  class="system_guest_information_color" style="padding-left: 12%;"> {{ showCompanyName(selected_mr.company) }} </span>
						<v-divider :thickness="50"></v-divider>
						<span v-if="nominationTicketMr > 0">
							<v-icon class="icon_color_main" size="30" left v-if="nominationTicketMr > 0">mdi-ticket</v-icon>Your Nomination Ticket: &nbsp; <v-spacer></v-spacer>
							<span class="system_guest_information_color" style="padding-left: 12%;"> {{ nominationTicketMr }}/1 </span>
							<v-divider :thickness="50"></v-divider>
							<v-icon class="icon_color_main" size="30" left>mdi-image</v-icon>Upload Image Chance: &nbsp; <v-spacer></v-spacer>
							<span class="system_guest_information_color" style="padding-left: 12%;"> {{ uploadChanceticketMr }}/1 </span>
							<v-divider :thickness="50"></v-divider>
						</span>
							<v-carousel hide-delimiters height="400">
								<v-carousel-item v-for="(item, index) in mr_images" :key="index">
										<template >
											<v-card style="background-color: black;">
												<v-container fluid class="picture">
													<v-img 
													:src="item.nominee_image" 
													contain
													class="ma-0 white--text align-end"
													height="400"
													></v-img>
												</v-container>
											</v-card>
										</template>
								</v-carousel-item>
							</v-carousel>
							<input @change="uploadImage($event, 'selected_mr')" ref="fileInput" class="d-none" id="upload-file" type="file" accept="image/png, image/jpeg"/>
							<v-divider :thickness="50"></v-divider>
					</v-card-text> -->
					<v-card-text v-if="settings.voting_start_date !== null ">
						<NomineeListComponent :nomineeType="'mr'" :settings="settings"/>
					</v-card-text>
						<!-- <v-card-text class="adaptive-text">
							<v-icon class="icon_color_main" size="40" left>mdi-ticket-confirmation-outline</v-icon>Reference Number: &nbsp; 
							<v-divider :thickness="50"></v-divider>
					</v-card-text> -->
					
					<v-card-text v-if="this.nomineeIDMr && nominationTicketMr > 0">
						<v-row>
							<v-col cols="12" sm="12">
								<v-btn style="width:100%;" @click="chooseFile" :disabled="(uploadChanceticketMr == 0) ? true : false" small class="border-dotted underline-animation-black" color="orange" dark>
									<span class="black--text"><v-icon class="icon_color_main" size="30"  left>mdi-upload-box</v-icon>Upload image</span>
								</v-btn>
							</v-col>
							<v-col cols="12" sm="12">
								<v-btn style="width:100%;" @click="nominateClicked('selected_mr')" :disabled="(nominationTicketMr == 1) && (settings.nomination_start_date !== null && settings.nomination_end_date === null) ? false : true" small class="border-dotted underline-animation-black" color="orange" dark>
									<span class="black--text"><v-icon class="icon_color_main" size="30"  left>mdi-vote</v-icon>NOMINATE</span>
								</v-btn>
							</v-col>
						</v-row>
					</v-card-text>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
	<v-divider :thickness="50"></v-divider>
	<v-expansion-panels class="system_font_family_primary" v-show="showTab && settings.voting_start_date !== null && settings.star_of_the_night == 1" v-model="showPeoplesChoicePanelMobileMs">
		<v-expansion-panel>
			<v-expansion-panel-header color="black" class="small-header">
				<span class="text-center expansion_title_color" style="font-size: 1.5rem; font-weight: bold;">
					<v-icon color="#f44336" class="mr-2">mdi-star-crescent</v-icon>MS. Star of the Night
				</span>
			</v-expansion-panel-header>
			<v-expansion-panel-content color="black" style="font-size: 1rem; font-weight: bold;" class="justify-content-start">
				<!-- <v-card-title style="justify-content: center;" v-if="!(settings.nomination_start_date !== null && settings.nomination_end_date !== null)">
					People's Choice Nomination
				</v-card-title> -->
				<v-card-title style="justify-content: center;">
					People's Choice Nominees
				</v-card-title>
					<!-- <v-card-text class="adaptive-text mt-2" v-if="(nominationTicketMs > 0 && settings.nomination_end_date === null)">
						<v-row no-gutters>
							<v-col class="pa-0" cols="12" sm="12" md="4">
								<v-autocomplete dense
									v-model="selected_ms.company"
									:items="dropdowns.company_list"
									@change="filterEmployeeNamesByCompany(selected_ms.company, 'selected_ms')"
									item-text="name"
									item-value="id"
									label="Select Company Name"
									color="orange"
									clearable
									>
								</v-autocomplete>
							</v-col>
							<v-col class="pa-0" align="center">
								<v-autocomplete
									dense
									label="Select Attendee Name"
									color="orange"
									v-model="selected_ms.nominee"
									:items="employee_names_female"
									@change="autoSelectCompany(selected_ms.nominee, 'selected_ms')"
									clearable
									>
								</v-autocomplete>
							</v-col>	
							<v-col class="pa-0" cols="12" sm="12" md="4">
								<v-btn class="black--text" color="orange" style="width: 100%" dark solo @click="appendName('selected_ms')" small>
									Search
								</v-btn>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-text class="adaptive-text" v-if="this.nomineeIDMs && (settings.nomination_start_date === null || settings.nomination_end_date === null)">
						<v-icon class="icon_color_main" size="30" left>mdi-account-check</v-icon>Participant Name: &nbsp; <v-spacer></v-spacer>
						<span class="system_guest_information_color" style="padding-left: 12%;"> {{ selected_ms.name }} </span>
						<v-divider :thickness="50"></v-divider>
						<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
							<path fill="currentColor" d="M18 15h-2v2h2m0-6h-2v2h2m2 6h-8v-2h2v-2h-2v-2h2v-2h-2V9h8M10 7H8V5h2m0 6H8V9h2m0 6H8v-2h2m0 6H8v-2h2M6 7H4V5h2m0 6H4V9h2m0 6H4v-2h2m0 6H4v-2h2m6-10V3H2v18h20V7z" />
						</svg>&nbsp;&nbsp;Company: &nbsp; <v-spacer></v-spacer>
						<span  class="system_guest_information_color" style="padding-left: 12%;"> {{ showCompanyName(selected_ms.company) }} </span>
						<v-divider :thickness="50"></v-divider>
						<span v-if="nominationTicketMs > 0">
							<v-icon class="icon_color_main" size="30" left v-if="nominationTicketMs > 0">mdi-ticket</v-icon>Your Nomination Ticket: &nbsp; <v-spacer></v-spacer>
							<span class="system_guest_information_color" style="padding-left: 12%;"> {{ nominationTicketMs }}/1 </span>
							<v-divider :thickness="50"></v-divider>
							<v-icon class="icon_color_main" size="30" left>mdi-image</v-icon>Upload Image Chance: &nbsp; <v-spacer></v-spacer>
							<span class="system_guest_information_color" style="padding-left: 12%;"> {{ uploadChanceticketMs }}/1 </span>
							<v-divider :thickness="50"></v-divider>
						</span>
							<v-carousel hide-delimiters height="400">
								<v-carousel-item v-for="(item, index) in ms_images" :key="index">
										<template >
											<v-card style="background-color: black;">
												<v-container fluid class="picture">
													<v-img 
													:src="item.nominee_image" 
													contain
													class="ma-0 white--text align-end"
													height="400"
													></v-img>
												</v-container>
											</v-card>
										</template>
								</v-carousel-item>
							</v-carousel>
							<input @change="uploadImage($event, 'selected_ms')" ref="fileInput" class="d-none" id="upload-file" type="file" accept="image/png, image/jpeg"/>
							<v-divider :thickness="50"></v-divider>
					</v-card-text> -->
					<v-card-text v-if="settings.voting_start_date !== null">
						<NomineeListComponent :nomineeType="'ms'" :settings="settings"/>
					</v-card-text>
						<!-- <v-card-text class="adaptive-text">
							<v-icon class="icon_color_main" size="40" left>mdi-ticket-confirmation-outline</v-icon>Reference Number: &nbsp; 
							<v-divider :thickness="50"></v-divider>
					</v-card-text> -->
					
					<v-card-text v-if="this.nomineeIDMs && nominationTicketMs > 0">
						<v-row>
							<v-col cols="12" sm="12">
								<v-btn style="width:100%;" @click="chooseFile" :disabled="(uploadChanceticketMs == 0) ? true : false" small class="border-dotted underline-animation-black" color="orange" dark>
									<span class="black--text"><v-icon class="icon_color_main" size="30"  left>mdi-upload-box</v-icon>Upload image</span>
								</v-btn>
							</v-col>
							<v-col cols="12" sm="12">
								<v-btn style="width:100%;" @click="nominateClicked('selected_ms')" :disabled="(nominationTicketMs == 1) && (settings.nomination_start_date !== null && settings.nomination_end_date === null) ? false : true" small class="border-dotted underline-animation-black" color="orange" dark>
									<span class="black--text"><v-icon class="icon_color_main" size="30"  left>mdi-vote</v-icon>NOMINATE</span>
								</v-btn>
							</v-col>
						</v-row>
					</v-card-text>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
	<v-divider :thickness="50"></v-divider>
	<v-expansion-panels class="system_font_family_primary" v-show="showTab && settings.share_your_moments == 1" v-model="showShareyourmomentsPanel">
		<v-expansion-panel>
			<v-expansion-panel-header color="black" class="small-header">
				<span class="text-center expansion_title_color" style="font-size: 1.5rem; font-weight: bold;">
					Share your moments
				</span>
			</v-expansion-panel-header>
			<v-expansion-panel-content color="black" style="font-size: 1.4rem; font-weight: bold;" class="justify-content-between">
				<div class="d-flex justify-content-center pa-4">
					<ShareYourMomentsComponent />
				</div>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
	<v-expansion-panels class="system_font_family_primary" v-show="showTab && settings.activities == 1"  v-model="showInfoPanelMobileActivities">
		<v-expansion-panel class="small-header">
			<v-expansion-panel-header color="black" class="small-header">
				<span class="text-center expansion_title_color" style="font-size: 1.5rem; font-weight: bold;">
					Activities
				</span>
			</v-expansion-panel-header>
			<v-expansion-panel-content color="black" style="font-size: 1.4rem; font-weight: bold;" class="justify-content-between">
				<ActivitiesCarouselComponentVue/>                        
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
	<v-expansion-panels class="system_font_family_primary" v-show="showTab && settings.program_flow == 1"  v-model="showInfoPanelMobileProgramFlow">
		<v-expansion-panel>
			<v-expansion-panel-header color="black">
				<span class="text-center expansion_title_color" style="font-size: 1.5rem; font-weight: bold;">
					Program Flow
				</span>
			</v-expansion-panel-header>
			<v-expansion-panel-content color="black" style="font-size: 1.4rem; font-weight: bold;" class="justify-content-between">
				<div style="width: 100%; height: 100%;">
					<v-carousel  hide-delimiters id="carousel" height="auto">
						<v-carousel-item v-for="(item, i) in flow_items" :key="i" style="">
							<v-card
								tile
							>
							<v-img 
								:src="checkImagePath(item.image_path)" 
								lazy-src="@/assets/loading_img.jpg"
								class="ma-0 white--text align-end"
							>
								<v-card-title style="height:200px;">{{ item.description }}</v-card-title>
							</v-img>
							</v-card>
						</v-carousel-item>
					</v-carousel>   
				</div>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
	<v-divider :thickness="50"></v-divider>
	<v-expansion-panels class="system_font_family_primary" v-show="showTab && settings.event_sitemap == 1"  v-model="showSitemapMobile">
		<v-expansion-panel>
			<v-expansion-panel-header color="black" class="small-header">
				<span class="text-center expansion_title_color" style="font-size: 1.5rem; font-weight: bold;">
					Event Sitemap
				</span>
			</v-expansion-panel-header>
			<v-expansion-panel-content color="black" style="font-size: 1.4rem; font-weight: bold;" class="justify-content-between">
				<v-divider :thickness="50"></v-divider>
					<EventSitemapCarouselComponent/>                        
				<v-divider :thickness="50"></v-divider>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>

	<!-- Desktop -->
	<v-expansion-panels class="system_font_family_primary" v-show="isDesktop && settings.voting_start_date !== null && settings.star_of_the_night == 1" v-model="showPeoplesChoicePanelMobileMr">
		<v-expansion-panel>
			<v-expansion-panel-header color="black" class="small-header">
				<span class="text-center expansion_title_color" style="font-size: 1.5rem; font-weight: bold;">
					<v-icon color="#67cdda" class="mr-2">mdi-star-crescent</v-icon>MR. Star of the Night
				</span>
			</v-expansion-panel-header>
			<v-expansion-panel-content color="black" style="font-size: 1rem; font-weight: bold;" class="justify-content-start">
				<!-- <v-card-title style="justify-content: center;" v-if="!(settings.nomination_start_date !== null && settings.nomination_end_date !== null)">
					People's Choice Nomination
				</v-card-title> -->
				<v-card-title style="justify-content: center;">
					People's Choice Nominees
				</v-card-title>
					<!-- <v-card-text class="adaptive-text mt-2" v-if="(nominationTicketMr > 0 && settings.nomination_end_date === null)">
						<v-row no-gutters>
							<v-col class="pa-0 ml-2" cols="12" sm="12" md="4">
								<v-autocomplete dense
									label="Select Company Name"
									color="orange"
									v-model="selected_mr.company"
									:items="dropdowns.company_list"
									@change="filterEmployeeNamesByCompany(selected_mr.company, 'selected_mr')"
									item-text="name"
									item-value="id"
									outlined
									clearable
									>
								</v-autocomplete>
							</v-col>
							<v-col class="pa-0 ml-2" align="center" md="4">
								<v-autocomplete
									dense
									label="Select Attendee Name"
									color="orange"
									v-model="selected_mr.nominee"
									:items="employee_names_male"
									@change="autoSelectCompany(selected_mr.nominee, 'selected_mr')"
									clearable
									outlined
								>
								</v-autocomplete>
							</v-col>
							<v-spacer></v-spacer>	
							<v-col class="pa-0 ml-2" cols="12" sm="12" md="3" align="end">
								<v-btn class="black--text" color="orange"  dark solo @click="appendName('selected_mr')">
									Search
								</v-btn>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-text class="adaptive-text" v-if="this.nomineeIDMr && (settings.nomination_start_date === null || settings.nomination_end_date === null)">
						<v-icon class="icon_color_main" size="30" left>mdi-account-check</v-icon>Participant Name: <span class="system_guest_information_color" style="padding-left: 1%;"> {{ selected_mr.name }} </span>  &nbsp; <v-spacer></v-spacer>
						<v-divider :thickness="50"></v-divider>
						<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
							<path fill="currentColor" d="M18 15h-2v2h2m0-6h-2v2h2m2 6h-8v-2h2v-2h-2v-2h2v-2h-2V9h8M10 7H8V5h2m0 6H8V9h2m0 6H8v-2h2m0 6H8v-2h2M6 7H4V5h2m0 6H4V9h2m0 6H4v-2h2m0 6H4v-2h2m6-10V3H2v18h20V7z" />
						</svg>&nbsp;&nbsp;Company: <span class="system_guest_information_color" style="padding-left: 1%;"> {{ showCompanyName(selected_mr.company) }} </span> &nbsp; <v-spacer></v-spacer>
						
						<v-divider :thickness="50"></v-divider>
						<span v-if="nominationTicketMr > 0">
							<v-icon class="icon_color_main" size="30" left v-if="nominationTicketMr > 0">mdi-ticket</v-icon>Your Nomination Ticket:<span class="system_guest_information_color" style="padding-left: 1%;"> {{ nominationTicketMr }}/1 </span> &nbsp; <v-spacer></v-spacer>
							
							<v-divider :thickness="50"></v-divider>
							<v-icon class="icon_color_main" size="30" left>mdi-image</v-icon>Upload Image Chance: <span class="system_guest_information_color" style="padding-left: 1%;"> {{ uploadChanceticketMr }}/1 </span>&nbsp; <v-spacer></v-spacer>
							
							<v-divider :thickness="50"></v-divider>
						</span>
							<v-carousel hide-delimiters height="400">
								<v-carousel-item v-for="(item, index) in mr_images" :key="index">
										<template >
											<v-card style="background-color: black;">
												<v-container fluid class="picture">
													<v-img 
													:src="item.nominee_image" 
													contain
													class="ma-0 white--text align-end"
													height="400"
													></v-img>
												</v-container>
											</v-card>
										</template>
								</v-carousel-item>
							</v-carousel>
							<input @change="uploadImage($event, 'selected_mr')" ref="fileInput" class="d-none" id="upload-file" type="file" accept="image/png, image/jpeg"/>
					</v-card-text> -->
					<v-card-text v-if="settings.voting_start_date !== null">
						<NomineeListComponent :nomineeType="'mr'" :settings="settings"/>
					</v-card-text>
						<!-- <v-card-text class="adaptive-text">
							<v-icon class="icon_color_main" size="40" left>mdi-ticket-confirmation-outline</v-icon>Reference Number: &nbsp; 
							<v-divider :thickness="50"></v-divider>
					</v-card-text> -->
					
					<!-- <v-card-text v-if="this.nomineeIDMr && nominationTicketMr > 0">
						<v-row no-gutters class="justify-content-center">
							<v-col class="ml-2" cols="12" sm="3">
								<v-btn style="width:100%;" @click="chooseFile" :disabled="(uploadChanceticketMr == 0) ? true : false" small class="border-dotted underline-animation-black" color="orange" dark>
									<span class="black--text"><v-icon class="icon_color_main" size="30"  left>mdi-upload-box</v-icon>Upload image</span>
								</v-btn>
							</v-col>
							<v-col class="ml-2" cols="12" sm="3">
								<v-btn style="width:100%;" @click="nominateClicked('selected_mr')" :disabled="(nominationTicketMr == 1) && (settings.nomination_start_date !== null && settings.nomination_end_date === null) ? false : true" small class="border-dotted underline-animation-black" color="orange" dark>
									<span class="black--text"><v-icon class="icon_color_main" size="30"  left>mdi-vote</v-icon>NOMINATE</span>
								</v-btn>
							</v-col>
						</v-row>
					</v-card-text> -->
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
	<v-divider :thickness="50"></v-divider>

	<v-expansion-panels class="system_font_family_primary" v-show="isDesktop && settings.voting_start_date !== null && settings.star_of_the_night == 1" v-model="showPeoplesChoicePanelMobileMs">
		<v-expansion-panel>
			<v-expansion-panel-header color="black" class="small-header">
				<span class="text-center expansion_title_color" style="font-size: 1.5rem; font-weight: bold;">
					<v-icon color="#f44336" class="mr-2">mdi-star-crescent</v-icon>MS. Star of the Night
				</span>
			</v-expansion-panel-header>
			<v-expansion-panel-content color="black" style="font-size: 1rem; font-weight: bold;" class="justify-content-start">
				<!-- <v-card-title style="justify-content: center;" v-if="!(settings.nomination_start_date !== null && settings.nomination_end_date !== null)">
					People's Choice Nomination
				</v-card-title> -->
				<v-card-title style="justify-content: center;">
					People's Choice Nominees
				</v-card-title>
					<!-- <v-card-text class="adaptive-text mt-2" v-if="(nominationTicketMs > 0 && settings.nomination_end_date === null)">
						<v-row no-gutters>
							<v-col class="pa-0 ml-2" cols="12" sm="12" md="4">
								<v-autocomplete dense
									v-model="selected_ms.company"
									:items="dropdowns.company_list"
									@change="filterEmployeeNamesByCompany(selected_ms.company, 'selected_ms')"
									item-text="name"
									item-value="id"
									label="Select Company Name"
									color="orange"
									clearable
									outlined
								>
								</v-autocomplete>
							</v-col>
							<v-col class="pa-0 ml-2" align="center" md="4">
								<v-autocomplete
									dense
									label="Select Attendee Name"
									color="orange"
									v-model="selected_ms.nominee"
									:items="employee_names_female"
									@change="autoSelectCompany(selected_ms.nominee, 'selected_ms')"
									clearable
									outlined
								>
								</v-autocomplete>
							</v-col>	
							<v-spacer></v-spacer>
							<v-col class="pa-0 ml-2" cols="12" sm="12" md="3" align="end">
								<v-btn class="black--text" color="orange" dark solo @click="appendName('selected_ms')">
									Search
								</v-btn>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-text class="adaptive-text" v-if="this.nomineeIDMs && (settings.nomination_start_date === null || settings.nomination_end_date === null)">
						<v-icon class="icon_color_main" size="30" left>mdi-account-check</v-icon>Participant Name: <span class="system_guest_information_color" style="padding-left: 1%;"> {{ selected_ms.name }} </span>&nbsp; <v-spacer></v-spacer>
						<v-divider :thickness="50"></v-divider>
						<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
							<path fill="currentColor" d="M18 15h-2v2h2m0-6h-2v2h2m2 6h-8v-2h2v-2h-2v-2h2v-2h-2V9h8M10 7H8V5h2m0 6H8V9h2m0 6H8v-2h2m0 6H8v-2h2M6 7H4V5h2m0 6H4V9h2m0 6H4v-2h2m0 6H4v-2h2m6-10V3H2v18h20V7z" />
						</svg>&nbsp;&nbsp;Company: <span  class="system_guest_information_color" style="padding-left: 1%;"> {{ showCompanyName(selected_ms.company) }} </span> &nbsp; <v-spacer></v-spacer>
						<v-divider :thickness="50"></v-divider>
						<span v-if="nominationTicketMs > 0">
							<v-icon class="icon_color_main" size="30" left v-if="nominationTicketMs > 0">mdi-ticket</v-icon>Your Nomination Ticket: <span class="system_guest_information_color" style="padding-left: 1%;"> {{ nominationTicketMs }}/1 </span>&nbsp; <v-spacer></v-spacer>
							<v-divider :thickness="50"></v-divider>
							<v-icon class="icon_color_main" size="30" left>mdi-image</v-icon>Upload Image Chance: <span class="system_guest_information_color" style="padding-left: 1%;"> {{ uploadChanceticketMs }}/1 </span>&nbsp; <v-spacer></v-spacer>
							
							<v-divider :thickness="50"></v-divider>
						</span>
							<v-carousel hide-delimiters height="400">
								<v-carousel-item v-for="(item, index) in ms_images" :key="index">
										<template >
											<v-card style="background-color: black;">
												<v-container fluid class="picture">
													<v-img 
													:src="item.nominee_image" 
													contain
													class="ma-0 white--text align-end"
													height="400"
													></v-img>
												</v-container>
											</v-card>
										</template>
								</v-carousel-item>
							</v-carousel>
							<input @change="uploadImage($event, 'selected_ms')" ref="fileInput" class="d-none" id="upload-file" type="file" accept="image/png, image/jpeg"/>
					</v-card-text> -->
					<v-card-text v-if="settings.voting_start_date !== null && settings.nomination_end_date !== null">
						<NomineeListComponent :nomineeType="'ms'" :settings="settings"/>
					</v-card-text>
					
					<!-- <v-card-text v-if="this.nomineeIDMs && nominationTicketMs > 0">
						<v-row no-gutters class="justify-content-center">
							<v-col class="ml-2" cols="12" sm="3">
								<v-btn style="width:100%;" @click="chooseFile" :disabled="(uploadChanceticketMs == 0) ? true : false" small class="border-dotted underline-animation-black" color="orange" dark>
									<span class="black--text"><v-icon class="icon_color_main" size="30"  left>mdi-upload-box</v-icon>Upload image</span>
								</v-btn>
							</v-col>
							<v-col class="ml-2" cols="12" sm="3">
								<v-btn style="width:100%;" @click="nominateClicked('selected_ms')" :disabled="(nominationTicketMs == 1) && (settings.nomination_start_date !== null && settings.nomination_end_date === null) ? false : true" small class="border-dotted underline-animation-black" color="orange" dark>
									<span class="black--text"><v-icon class="icon_color_main" size="30"  left>mdi-vote</v-icon>NOMINATE</span>
								</v-btn>
							</v-col>
						</v-row>
					</v-card-text> -->
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
	<v-divider :thickness="50"></v-divider>
		<!-- <v-expansion-panel>
			<v-expansion-panel-header color="black" class="small-header">
				<span class="text-center expansion_title_color" style="font-size: 1rem; font-weight: bold;">
					People's Choice
				</span>
			</v-expansion-panel-header>
			<v-expansion-panel-content color="black" style="font-size: 1.4rem; font-weight: bold;" class="justify-content-start">
				<v-divider :thickness="50"></v-divider>
				<v-expansion-panel-content color="black" style="font-size: 1rem; font-weight: bold;" class="justify-content-center">
					<div style=">
						<v-divider :thickness="50"></v-divider> -->
						<!-- <v-card-title style="justify-content: center; min-height: auto; font-size: 1.5rem;" class="adaptive-text">
						<v-row>
							<v-autocomplete label="Select Company Name"></v-autocomplete>
							<v-text-field
								@keyup.enter="submitReferenceNumber" 
								label="ENTER REFERENCE NUMBER"
								persistent-hint
								regular
								tile
								outlined	
								color="orange"	
								class="fill-width"
								v-model="referenceNumber">
							</v-text-field>
							<v-btn color="orange" class="mx-auto" xs12 sm6 dark solo x-large>
								Search
							</v-btn>
						</v-row>
					</v-card-title>
					<v-container>
						<v-divider :thickness="50"></v-divider>
						<div style="display: flex; justify-content: between;">
							<div style="width: 100%; justify-content: start;">
							<v-container fluid class="picture">
								<v-carousel>
									<v-carousel-item>
										<v-img
										src="@/assets/head.png"
										contain
										aspect-ratio="2.5"
										></v-img>
									</v-carousel-item>
									<v-carousel-item>
										<v-img
											src="@/assets/head.png"
											contain
											aspect-ratio="2.5"
										></v-img>
										</v-carousel-item>
									</v-carousel>
									
							</v-container>						
		</div>
		</div>	
	</v-container>				   
		</div>
		</v-expansion-panel-content>
				<v-divider :thickness="50"></v-divider>
			</v-expansion-panel-content> -->
		<!-- </v-expansion-panel>  -->
	<v-expansion-panels class="system_font_family_primary" v-if="isDesktop && settings.share_your_moments == 1"  v-model="showShareMomentsPanel">
		<v-expansion-panel>
			<v-expansion-panel-header color="black" class="small-header">
				<span class="text-center expansion_title_color" style="font-size: 1.5rem; font-weight: bold;">
					Share your moments
				</span>
			</v-expansion-panel-header>
			<v-expansion-panel-content color="black" style="font-size: 1.4rem; font-weight: bold;" class="justify-content-between">
				<v-divider :thickness="50"></v-divider>
					<div class="d-flex justify-content-center pa-4">
						<ShareYourMomentsComponent />
					</div>
				<v-divider :thickness="50"></v-divider>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
	<v-divider :thickness="50"></v-divider>
	<v-expansion-panels class="system_font_family_primary" v-if="isDesktop && settings.activities == 1"  v-model="showInfoPanelActivities">
		<v-expansion-panel>
			<v-expansion-panel-header color="black" class="small-header">
				<span class="text-center expansion_title_color" style="font-size: 1.5rem; font-weight: bold;">
					Activities
				</span>
			</v-expansion-panel-header>
			<v-expansion-panel-content color="black" style="font-size: 1.4rem; font-weight: bold;" class="justify-content-between">
				<v-divider :thickness="50"></v-divider>
					<ActivitiesCarouselComponentVue/>                        
				<v-divider :thickness="50"></v-divider>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
	<v-divider :thickness="50"></v-divider>

	<v-expansion-panels class="system_font_family_primary" v-if="isDesktop && settings.program_flow == 1"  v-model="showInfoPanelProgramFlow">
		<v-expansion-panel>
			<v-expansion-panel-header color="black">
				<span class="text-center expansion_title_color" style="font-size: 1.5rem; font-weight: bold;">
					Program Flow
				</span>
			</v-expansion-panel-header>
			<v-expansion-panel-content color="black" style="font-size: 1.4rem; font-weight: bold;" class="justify-content-between">
				<div style="width: 100%; height: 100%;">
					<v-carousel  hide-delimiters id="carousel" height="auto">
						<v-carousel-item v-for="(item, i) in flow_items" :key="i" style="">
							<v-card
								tile
							>
							<v-img 
								:src="checkImagePath(item.image_path)" 
								lazy-src="@/assets/loading_img.jpg"
								class="ma-0 white--text align-end"
								height="inherit"
								width="inherit"
								contain
							>
								<v-card-title style="height:200px;">{{ item.description }}</v-card-title>
							</v-img>
							</v-card>
						</v-carousel-item>
					</v-carousel>   
				</div>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
	<v-divider :thickness="50"></v-divider>

	<v-expansion-panels class="system_font_family_primary" v-if="isDesktop && settings.event_sitemap == 1"  v-model="showSitemapDesktop">
		<v-expansion-panel>
			<v-expansion-panel-header color="black" class="small-header">
				<span class="text-center expansion_title_color" style="font-size: 1.5rem; font-weight: bold;">
					Event Sitemap
				</span>
			</v-expansion-panel-header>
			<v-expansion-panel-content color="black" style="font-size: 1.4rem; font-weight: bold;" class="justify-content-between">
				<v-divider :thickness="50"></v-divider>
					<EventSitemapCarouselComponent/>                        
				<v-divider :thickness="50"></v-divider>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
	<v-divider :thickness="50"></v-divider>

	</v-main>
</template>

<script>
	import Swal from 'sweetalert2';
	import { defineComponent } from 'vue';
	import { mapGetters } from 'vuex';


	import ActivitiesCarouselComponentVue from '@/components/expansions/ActivitiesCarouselComponent.vue';
	import EventSitemapCarouselComponent from '@/components/expansions/EventSitemapCarouselComponent.vue';
	import ShareYourMomentsComponent from '@/components/expansions/ShareYourMomentsComponent.vue';
	import NomineeListComponent from '@/components/expansions/NomineeListComponent.vue'
	import SharedFunctionsComponent from '@/components/shared/SharedFunctionsComponent.vue'

	defineComponent({
		name: 'MdiCompany',
		name: 'MdiHashtag',
	});
	export default {
		name: 'Header',
    	mixins: [SharedFunctionsComponent],

	data() {
		return {
			selected_company: null,
			selected_name: null,
			showInfoPanel: 0,
			showInfoPanelActivities: -1,
			showInfoPanelProgramFlow: -1,
			showInfoPanelMobile:  0,
			showInfoPanelMobileActivities:  -1,
			showInfoPanelMobileProgramFlow:  -1,
			showPeoplesChoicePanelMobileMr: -1,
			showPeoplesChoicePanelMobileMs: -1,
			showPeoplesChoicePanel: -1,
			showShareMomentsPanel: -1,
			showShareyourmomentsPanel: -1,
			showSitemapMobile: -1,
			showSitemapDesktop: -1,
			referenceNumber: null,
			employeeName: '',
			NominatorID: '',
			company_name: '',
			all_company: '',
			employee_names_male: '',
			employee_names_female: '',
			disabled_view: false,
			programFlowExpanded: true,
			showEventInformation: false,
			tableNumber: '',
			buffetTable: '',
			nominationTicketMr: '',
			uploadChanceticketMr: '',
			nominationTicketMs: '',
			uploadChanceticketMs: '',
			menuOpen: false,
			test: '',
			mobileBarTicket: '',
			nomineeIDMr: '',
			nomineeIDMs: '',
			qrImage: '',
			clicked: true,
			isDesktop: window.innerWidth > 1000,
			showTab: window.innerWidth <= 1000,
			showSelectedName: false,
			showSelectedCompany: false,
			isHover: false,
			isHoverShareyourmoments: false,
			selectedCompany: null,
			items: [
			{
				src: require('@/assets/banner.jpg.png'),
			},
			{
				src: require('@/assets/banner.jpg.png'),
			},
			{
				src: require('@/assets/banner.jpg.png'),
			},
			],
			currentItem: 0, 
			flow_items: [],
			sitemap_items: [],
			images: [],
			employee_names_male_default:[],
			employee_names_female_default:[],
			settings: {
				nomination_start_date: null,
				nomination_end_date: null,
				share_your_moments: null,
				star_of_the_night: null,
				activities: null,
				program_flow: null,
				event_sitemap: null,
				data_grouping: null,
				voting_start_date: null,
				voting_end_date: null,
			},
			selected_mr:{
				company: null,
				nominee: null
			},
			selected_ms:{
				company: null,
				nominee: null
			},
			uploadedImage: null,
			first_name: '',
			ms_images: [],
			mr_images: [],
			dropdowns: {
				company_list: []
			},
			company_id: null,
			ticket_nums: [],
			ticket_num_length: 0,
			ticket_search: '',
			default_ticket_nums: [],
			with_guests:{
				dialog: false,
				items: [],
				selected: []
			},
			list_loading:{
                with_guest: false
            },
			fullScreenTicket: null,
			full_screen:{
				dialog: false
			},
			event_id: null
		};
	},
	async mounted() {
		window.addEventListener('resize', this.handleResize);
		await this.handleResize();

	},
	components:{
		ActivitiesCarouselComponentVue,
		EventSitemapCarouselComponent,
		ShareYourMomentsComponent,
		NomineeListComponent,
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize);
	},
	async created() {
		document.title = 'Welcome';
		await this.getEventID();
		this.submitEmployeeId(1);
		// await this.fetchStaroftheNight();
	},
	computed:{
		...mapGetters([
			'GET_REMAINING_GUEST',
			'GET_REMIND_GUEST',
			'GET_PREFERENCES',
			'GET_EVENT_ID'
		])
	},
	methods: {
		redirectToLandingPage() {
			localStorage.removeItem("token");
			localStorage.removeItem("refNumber");
			this.$router.push('/landing');
			this.reloadPage();
		},
		toggleSheet() {
			this.$emit('toggle-sheet');
		},
		reloadPage() {
			window.location.reload();
		},
		handleResize() {
			this.isDesktop = window.innerWidth > 1000;
			this.clicked = this.isDesktop;
			this.clicked = !this.clicked;
			this.showTab = window.innerWidth <= 1000;
		},
		toggleSVG() {
			this.clicked = !this.clicked;
		},
		toggleMenu() {
			this.menuOpen = !this.menuOpen;

		},
		async submitEmployeeId(show_swal = 0) {
			this.referenceNumber = localStorage.getItem("refNumber");
			let payload = {
				url: "check-employee-bar_register",
				id: this.referenceNumber,
			};
			// Swal.showLoading();
			try {
				// await axios.post(process.env.VUE_APP_API_HOST + '/api/check-employee-bar_register', {
				const response = await this.$store.dispatch("urlPost", payload).then((response)=>{
					this.employeedData = response.remaining_tickets;
					this.tableNumber = response["0"].table_num;
					this.buffetTable = response["0"].buffet_meal_id;
					this.mobileBarTicket = response["0"].mobile_bar_count;
					this.employeeName = response["0"].full_name;
					this.first_name = response["0"].first_name;
					this.company_name = response["0"].participant_company_name;
					this.nominationTicketMr = response["0"].nomination_ticket_mr;
					this.uploadChanceticketMr = response ["0"].upload_ticket_mr;
					this.nominationTicketMs = response["0"].nomination_ticket_ms;
					this.uploadChanceticketMs = response ["0"].upload_ticket_ms;
					this.NominatorID = response ["0"].id;
					this.nomineeIDMr = response["0"].nomineeIDMr;
					this.nomineeIDMs = response["0"].nomineeIDMs;
					this.company_id = response["0"].company_id;
					this.ticket_num_length = response["0"].no_of_tickets;
					this.default_ticket_nums = response["0"].ticket_nums;
					this.ticket_nums = this.splitArray(response["0"].ticket_nums, 10);



					this.selected_mr.name = response ["0"].selected_mr_name;
					this.selected_mr.company = this.selected_mr.company ? this.selected_mr.company : response ["0"].selected_mr_company;
					this.selected_ms.name = response ["0"].selected_ms_name;
					this.selected_ms.company = this.selected_ms.company ? this.selected_ms.company : response ["0"].selected_ms_company;

					let item = {
						mr_vote_count: response["0"].MRSOF,
						ms_vote_count: response["0"].MSSOF,
					}
					this.$store.commit('VOTED', item)
					// this.nomineeID = response ["0"].nominee_id;
					// this.selected.name = response ["0"].selected_name;
					// this.selected.company = response ["0"].nominee_company_name;
					this.$store.commit('CURRENT_USER', response ["0"].id);
					if(show_swal == 1){
						if(this.GET_REMAINING_GUEST.length > 0){
						// if(this.GET_REMAINING_GUEST.length > 0 && this.GET_REMIND_GUEST > 0){
							this.with_guests.dialog = true;
						}else{
							Swal.fire({
								title: 'Welcome',
								html: `${response["0"].full_name}<br>of<br>${response["0"].participant_company_name}`,
								icon: 'success',
								allowOutsideClick: true,
								showConfirmButton: false, // Remove the confirm button
								timer: 2000, // Auto close after 2 seconds
								customClass: {
									confirmButton: 'orange-button',
								},
							}).then(() => {
								this.showInfoPanel = 0;
								this.showInfoPanelActivities = -1;
								this.showInfoPanelProgramFlow = -1;
								this.showInfoPanelMobile = 0;
								this.showInfoPanelMobileActivities = -1;
								this.showInfoPanelMobileProgramFlow = -1;
								this.showPeoplesChoicePanelMobileMr = -1;
								this.showPeoplesChoicePanelMobileMs = -1;
								this.showPeoplesChoicePanel= -1;
								this.showShareMomentsPanel = -1;
								this.showShareyourmomentsPanel = -1;
								this.showSitemapMobile = -1;
								this.showSitemapDesktop = -1;
								//   this.generateQR(this.referenceNumber);
								this.showEventInformation = true;
							});
						}
					}
				});
				}catch (error) {
				if (error.response.status === 500 || error.response.status === 401) {
				Swal.fire({
					title: "<strong><u>NOT FOUND</u></strong>",
					text: "Reference Number Not Found!",
					icon: 'warning',
					allowOutsideClick: false,
					customClass: {
					confirmButton: 'orange-button',
					}
				}).then((result) => {
					if (result.isConfirmed) {
						this.$router.push('/landing');
					}
				});
				} else {
				console.error(error);
				}
			}
		},
		getProgramFlowImages(){
			let payload = {
				url: 'getProgramFlowImages',
				event_id: this.event_id
			}
			this.$store.dispatch('urlGet', payload).then((res)=>{
				this.flow_items = res.data
			}); 
		},
		async fetchStaroftheNight() {
			let payload = {
				url: "event-participants",
				id: this.data,
				status_id: 2,
				export: 1
			};
			try {
				await this.$store.dispatch("urlGet", payload).then((res) => {
					this.employeeDatas = res.data;
					let companyNames = new Set();
					let fullNames = new Set();
					this.employeeDatas.forEach((employeeData) => {
						companyNames.add(employeeData.company_name);
						fullNames.add(employeeData.full_name);
					});
					this.all_company = Array.from(companyNames);
					// this.employee_names = Array.from(fullNames);
					this.employee_names_male = this.employeeDatas.filter((x)=> x.gender == 'M').map((e)=> e.full_name)
					this.employee_names_female = this.employeeDatas.filter((x)=> x.gender == 'F').map((e)=> e.full_name)
					this.employee_names_male_default = this.employee_names_male;
					this.employee_names_female_default = this.employee_names_female;
					this.employee_names_employee_names_female_default = this.employee_names_employee_names_female;
					this.employee_names_default = Array.from(fullNames);
					if(this.referenceNumber){
						let nomineeTypes = ['selected_mr', 'selected_ms'];
						nomineeTypes.forEach((e)=>{
							this.getNomineeImages(e);
						})
					}
				});
				this.$store.commit('NEW_MASTER_DATA', true);
			} catch (error) {
				console.error(error);
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Failed to fetch data!',
				});
			}
		},
		filterEmployeeNamesByCompany(selectedCompany, key) {
			if(selectedCompany){
				this.selected_name = null;
				let filteredEmployeeNames = this.employeeDatas.filter(employeeData => employeeData.company_id === selectedCompany && (key == 'selected_mr' ? employeeData.gender == 'Male' : employeeData.gender == 'Female'));
				if(key == 'selected_mr'){
					this.employee_names_male = filteredEmployeeNames.map(employeeData => employeeData.full_name);
				}else{
					this.employee_names_female = filteredEmployeeNames.map(employeeData => employeeData.full_name);
				}
			}else{
				if(key == 'selected_mr'){
					this.employee_names_male = this.employee_names_male_default;
				}else{
					this.employee_names_female = this.employee_names_female_default;
				}
			}
		},
		autoSelectCompany(selectedName, key) {
			const attendee = this.employeeDatas.find(employeeData => employeeData.full_name === selectedName);
			if (attendee) {
				this[key].company = attendee.company_id;
				this[key].name = attendee.full_name;
			}
			this.getNomineeImages(key);
		},
		chooseFile() {
			this.$refs.fileInput.click();
		},
		uploadImage(event, key) {
			const file = event.target.files[0];
			const reader = new FileReader();
			this.uploadedImage = file;

			reader.onload = () => {
				this.uploadedImage = reader.result;
				if(key == 'selected_mr'){
					this.mr_images.unshift({
						nominee_image: reader.result
					})
				}else{
					this.ms_images.unshift({
						nominee_image: reader.result
					})
				}
				// const payload = {
				// 	url: "nomination-image",
				// 	prize_image: prize_image,
				// 	nomineeID: this.nomineeID,
				// 	nominatorID: this.NominatorID,
				// };

				// this.$store.dispatch("urlPost", payload)
				// 	.then(response => {
				// 		Swal.fire({
				// 			title: "Image Uploaded Successfully!",
				// 			html: `<br>Image added to<br>${this.selected.name}<br>of<br>${this.selected.company}`,
				// 			icon: 'success',
				// 			allowOutsideClick: false,
				// 			customClass: {
				// 				confirmButton: 'orange-button',
				// 			},
				// 		});
				// 		this.submitEmployeeId();
				// 	})
				// 	.catch(error => {
				// 		console.error('Error uploading image', error);
				// 	});
			};
			reader.readAsDataURL(this.uploadedImage);
		},
		async nominateClicked(key) {
			let payload = {
				url: "eventNominees",
				NominatorID: this.NominatorID,
				NomineeID: key == 'selected_mr' ? this.nomineeIDMr : this.nomineeIDMs,
				prize_image: this.uploadedImage,
				type: key
			};

			Swal.fire({
				title: 'Nominating Participant',
				allowOutsideClick: false,
				showCancelButton: false,
				showConfirmButton: false,
			});
			Swal.showLoading();

			try {
				await this.$store.dispatch("urlPost", payload).then((response)=>{
					Swal.close();
					Swal.fire({
						title: response.message,
						//   html: ` Welcome! <br> ${response[0].full_name} <br> of <br> ${response[0].company_name}`,
						html: `<br>You have nominated<br>${this[key].name}<br>of<br>${this.showCompanyName(this[key].company)}`,
						icon: 'success',
						allowOutsideClick: false,
						customClass: {
							confirmButton: 'orange-button',
						},
					}).then((result) => {
						if (result.isConfirmed) {
							this.showInfoPanel = 0;
						}
					});
					this.submitEmployeeId();
					this.getNomineeImages(key);
				});
			} catch (error) {
				if (error.response && error.response.status === 500) {
					Swal.fire({
						title: "",
						text: error.response.data.message,
						icon: 'warning',
						customClass: {
						confirmButton: 'orange-button',
						},
					}).then((result) => {
						if (result.isConfirmed) {
							location.reload();
						}
					});
				} else {
					console.error(error);
				}
				Swal.close();
			}
		},
		appendName(key) {
			const selectedFullName = this[key].nominee; 
			const selectedEmployee = this.employeeDatas.find(employee => employee.full_name === selectedFullName);
			if(key == 'selected_mr'){
				this.nomineeIDMr = selectedEmployee ? selectedEmployee.id : null;
			}else{
				this.nomineeIDMs = selectedEmployee ? selectedEmployee.id : null;
			}
			this.getNomineeImages(key);
			this.showSelectedName = true;
			this.showSelectedCompany = true;
		},
		async getNominationSettings(){
            let payload = {
                type: 'Nomination',
                url: 'moduleSettings',
            };
            await this.$store.dispatch("urlGet", payload).then(async (res)=>{
            	Object.keys(this.settings).forEach((key)=>{
					let setting = res.find((x)=> x.parameter == key);
					if(setting){
						this.settings[key] = setting.value
					}
                })
            })
		},
		getNomineeImages(key){
			if(key == 'selected_mr'){
				let payload = {
					url: "getNomineeImages",
					event_participant_id: this.nomineeIDMr
				}
				this.$store.dispatch('urlPost', payload).then((response) => {
					this.mr_images = response.data
				})
			}else{
				let payload = {
					url: "getNomineeImages",
					event_participant_id: this.nomineeIDMs
				}
				this.$store.dispatch('urlPost', payload).then((response) => {
					this.ms_images = response.data
				})

			}
		},
		async getExpansionsSettings(){
            let payload = {
                type: 'Expansions',
                url: 'moduleSettings'
            };
            await this.$store.dispatch("urlGet", payload).then(async (res)=>{
            	Object.keys(this.settings).forEach((key)=>{
					let setting = res.find((x)=> x.parameter == key);
					if(setting){
						this.settings[key] = setting.value
					}
                })
            })
		},
		async getCompanyDropdowns(){
			let payload = {
				url: 'companyRoute'
			}
			this.$store.dispatch('urlGet', payload).then((res)=>{
				this.dropdowns.company_list = res.data
			});
		},
		showCompanyName(company_id){
			let company_details = this.dropdowns.company_list.find((x)=> x.id == company_id)
			if(company_details){
				return company_details.name
			}else{
				return ''
			}
		},
		getWithGuest(){
            this.list_loading.with_guest = true
            let payload = {
				url: 'getEmployeeSearch',
				company_id: this.company_id,
			}
			this.$store.dispatch('urlPost', payload).then((res)=>{
                this.with_guests.items = res.filter(x => x.status_id == 1 && x.employee_id != this.employeeId);
                this.list_loading.with_guest = false
                // this.with_guest_items = res.filter((x) => x.employee_id != this.employeeId);
			})
        },
		triggerSwalRemaining(){
			let filtered = this.GET_REMAINING_GUEST.filter(function (el) {
				if(!el.selected){
					return el;
				}
			}).map((ex) => ex.full_name).join(', <br>');
			if(filtered.length > 0){
				Swal.fire({
					title: "Please remind the following guest to register on the website",
					html: filtered,
					icon: 'success',
					customClass: 'swal-height'
				}).then((res)=>{
					if(res.isConfirmed){
						Swal.fire({
							title: 'Welcome',
							html: `${this.employeeName}<br>of<br>${this.company_name}`,
							icon: 'success',
							allowOutsideClick: true,
							showConfirmButton: false, // Remove the confirm button
							timer: 2000, // Auto close after 2 seconds
							customClass: {
								confirmButton: 'orange-button',
							},
						}).then(() => {
							this.showInfoPanel = 0;
							this.showInfoPanelActivities = -1;
							this.showInfoPanelProgramFlow = -1;
							this.showInfoPanelMobile = 0;
							this.showInfoPanelMobileActivities = -1;
							this.showInfoPanelMobileProgramFlow = -1;
							this.showPeoplesChoicePanelMobileMr = -1;
							this.showPeoplesChoicePanelMobileMs = -1;
							this.showPeoplesChoicePanel= -1;
							this.showShareMomentsPanel = -1;
							this.showShareyourmomentsPanel = -1;
							this.showSitemapMobile = -1;
							this.showSitemapDesktop = -1;
							//   this.generateQR(this.referenceNumber);
							this.showEventInformation = true;
						});
					}
				})
			}
		},
		confirmWithGuests(){
			let payload = {
				event_participant_ids: this.GET_REMAINING_GUEST.filter((x)=> x.selected == true).map(e=> {return e.id}),
				url: 'confirmWithGuests'
			}
			this.$store.dispatch('urlPost', payload).then((res)=>{
				Swal.fire(res.icon.toUpperCase(),res.message,res.icon);
				this.with_guests.dialog = false;
				this.triggerSwalRemaining();
			})
		},
		selectGuest(item){
            item.selected = !item.selected
        },
		showTicketFull(ticket){
			this.fullScreenTicket = ticket;
			this.full_screen.dialog = true;
		},
		async getEventID(){
			const searchParams = new URLSearchParams(window.location.search);
			let eventLink = searchParams.get('ec');
			let payloadLink = {
				url: 'checkEventLink',
				eventLink: eventLink
			}
			await this.$store.dispatch('urlPost', payloadLink).then((res)=>{
				this.$store.commit('EVENT_ID', res)
				this.event_id = res
				this.getNominationSettings();
				this.getExpansionsSettings();
				// this.getCompanyDropdowns();
				this.getProgramFlowImages();
			})
		},
		checkImagePathLogo(image_path) {
            return process.env.VUE_APP_API_HOST + "/" + image_path
        },
	},
	watch:{
		ticket_search:{
			handler(val){
				if(val){
					let filtered = this.default_ticket_nums.filter(item => item.toLowerCase().indexOf(val) > -1);
					this.ticket_nums = this.splitArray(filtered, 10)
				}else{
					this.ticket_nums = this.splitArray(this.default_ticket_nums, 10);
				}
			}
		},
		GET_REMAINING_GUEST:{
			handler(val){
				this.with_guests.items = val;
			}
		},
		'with_guests.dialog':{
			handler(val){
				if(!val){
					// this.triggerSwalRemaining();
				}
			}
		},
		GET_EVENT_ID:{
			handler(val){
				// this.event_id = val;
			}
		}
	}
// 		async generateQR(employee_id) { 
// 		Swal.showLoading();
// 			try {
// 				const response = await axios.post(process.env.VUE_APP_API_HOST + '/api/generate_QR', {
// 					employee_id: employee_id
// 				});
// 				const svgDataUri = `data:image/svg+xml;base64,${btoa(response.data)}`;
// 		this.qrImage = svgDataUri;
// 		} catch (error) {
// 		console.error(error);
// 		}
// 		},
// 		async downloadQR() {
//   const canvas = document.createElement('canvas');
//   const ctx = canvas.getContext('2d');
//   const img = new Image();
//   img.onload = () => {
//     // Increase canvas dimensions for higher quality
//     const scaleFactor = 5; // Increase this value for higher quality
//     canvas.width = img.width * scaleFactor;
//     canvas.height = img.height * scaleFactor;
//     ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
//     const link = document.createElement('a');
//     link.href = canvas.toDataURL('image/png');
//     link.download = `${this.employeeName}_QR_Code.png`;
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//     Swal.fire({
//       title: 'CHECK YOUR DOWNLOADS!',
//       text: 'Your QR Code has been downloaded successfully!',
//       icon: 'success',
//       allowOutsideClick: false,
//       customClass: {
//         confirmButton: 'orange-button',
//       },
//     });
//   };
//   img.src = this.qrImage;
// },
	}
	
</script>

<style>
	.transparent-btn { background-color: transparent; border: none; }

	.slide-enter-active, .slide-leave-active {
	transition: transform 0.5s;
	}
	#ingco-logo-img{
	height: 40px;
	}
	.slide-enter, .slide-leave-to {
	transform: translateX(-6%);
	}
	body {
	overflow: hidden;
	}
	.adaptive-text {
	font-size: 16px;
	justify-content: center;
	}
	@media (max-width: 600px) {
	.adaptive-text {
		font-size: 14px; 
	}
	}
	.Adaptive-text-Note {
	font-size: 13px;
	justify-content: center;
	}
	@media (max-width: 600px) {
	.Adaptive-text-Note {
		font-size: 9px;
	}
	}
	.small-header {
	padding-top: 1px; /* Adjust as needed */
	padding-bottom: 1px; /* Adjust as needed */
	}
	body {
	background-color: black !important;
	}

	.image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 800px; /* Adjust this value as needed */
    }
	.swal-height {
		height: 100vh;
		overflow: auto;
		font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif !important;
	}

	.landscape-dialog {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.landscape-card {
		width: inherit !important; /* Set width to viewport height after rotation */
		height: inherit !important; /* Set height to viewport width after rotation */
		display: flex;
		justify-content: center;
		align-items: center;
	}


</style>
