<script>
    import moment from "moment";

    export default {
        methods: {
            toCurrency(currency, amount, decimalPrecision ) {
                const options = {
                    minimumFractionDigits: decimalPrecision,
                    maximumFractionDigits: decimalPrecision,
                };

                if (currency) {
                    options.style = 'currency';
                    options.currency = currency.toUpperCase();
                }

                return parseFloat(amount).toLocaleString('en-US', options);
            },
            // checkAccess (dep, mod, axn) {
            //     axios.get('checkAccess',{
            //         params: {
            //             department_code: dep,
            //             module: mod,
            //             action: axn,
            //         }
            //     }).then(response => {
            //         this.userAccess[axn] =  response.data;
            //     });
            // },
            decimalOnly ($event) {
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                    $event.preventDefault();
                }
            },
            numberOnly ($event) {
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
                if (keyCode < 48 || keyCode > 57) {
                    $event.preventDefault();
                }
            },
            thousandSeprator (amount) {
                if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
                    return amount.toString().trim().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    return amount;
                }
            },
            round(value, decimals) {
                return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            },
            getTwoDecimals (amount, places = 2) {
                var formatted_amount = ''
                if(isNaN(amount)){
                    if(amount != null && amount != ''){
                        formatted_amount = parseFloat(amount.replace(/,/g, ''))
                        formatted_amount = Math.trunc(formatted_amount * Math.pow(10, places)) / Math.pow(10, places)
                    }
                }else{
                    formatted_amount = Math.trunc(amount * Math.pow(10, places)) / Math.pow(10, places)
                }

                if (formatted_amount == '') {
                    return 0.00
                }
                return this.thousandSeprator(formatted_amount.toFixed(places))
            },
            getDatesFromRange(startDate, endDate, return_type = 'array') {
                var dates = []
                var currDate = moment(startDate).startOf('day')
                var lastDate = moment(endDate).startOf('day')

                while(currDate.diff(lastDate) <= 0) {
                    switch (return_type) {
                        case 'array':
                            dates.push(currDate.clone().format('YYYY-MM-DD'))
                            break
                        case 'object':
                            dates.push(Object.assign({},{
                                value: currDate.clone().format('YYYY-MM-DD'),
                                text: currDate.clone().format('YYYY-MM-DD (dddd)')
                            }))
                            break
                        default:
                    }
                    currDate = currDate.add(1, 'days')
                }
                return dates
            },
            upperCaseFirstLetter(str, allCaps = false){
                console.log(allCaps);
                const arr = str.split(" ");
                for (var i = 0; i < arr.length; i++) {
                    if (allCaps) {
                        arr[i] = arr[i].toUpperCase();
                    } else {
                        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                    }
                }
                str = arr.join(" ");
                return str
            },
            leadingZeros(number = 0,count = 4){

                let digitCount = number.toString().length
                let stringedNumber = number.toString()

                if(digitCount > count){
                    return 0
                }

                while(digitCount != count){
                    stringedNumber = '0' + stringedNumber
                    digitCount++
                }

                return stringedNumber;
            },

            arraySlice(array,count = 10){
                return array.reduce((resultArray, item, index) => {
                    const chunkIndex = Math.floor(index/count)

                    if(!resultArray[chunkIndex]) {
                        resultArray[chunkIndex] = [] // start a new chunk
                    }

                    resultArray[chunkIndex].push(item)

                    return resultArray
                    }, [])
            },

            formatCurrencyPHP(amount) {
                return parseFloat(amount).toLocaleString('en-PH', {style: 'currency', currency: 'PHP'});
            },

            sliceIntoChunks(arr, chunkSize) {
                const res = [];
                for (let i = 0; i < arr.length; i += chunkSize) {
                    const chunk = arr.slice(i, i + chunkSize);
                    res.push(chunk);
                }
                return res;
            },
            errorObjects(type){
                switch(type){
                    case 1:
                        return {
                            title:'Warehouse Not Found',
                            color:'#8271FA'
                        }
                    case 2:
                        return {
                            title:'Item Not Found',
                            color:'#71ADFA'
                        }
                    case 3:
                        return {
                            title:'Can\'t request at the sames warehouse',
                            color:'#FA7192'
                        }
                }
            },

            formatDate(value) {
                if (!value) {
                    return ''
                }
                return value.indexOf(":") != -1
                    ? new Date(Date.parse(value.replace(" ", "T") + ".000Z"))
                    : new Date(value);
            },
            formatDateandTime(value) {
                if (!value) {
                    return ''
                }
                return moment(String(value)).format('MM/DD/YYYY hh:mm A')
            },
            getDistinctObjects(array, property) {
            return array.filter((object, index) => {
                const firstIndex = array.findIndex(
                        (item) => item[property] === object[property]
                    );
                    return index === firstIndex;
                });
            },
            formattedDate(date) {
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');
                return `${year}-${month}-${day}`;
            },
            dateWithFormat(value ='',format ='') {
                if (!value || !format) {
                    return ''
                }
                return moment(String(value)).format(format)
            },

            // devide array base on desired attribute
            // return data based on 3rd parameter (array or objectonly)
            divideArrayByAttribute(array = [],attributeName = '', returnType = 'array'){
                if(array.length == 0 || !attributeName){
                    console.log('error in divideArrayByAttribute function')
                    return array
                }

                let attributes = [...new Set(array.map(e=> e[attributeName]))]
                let cleanArray = []
                attributes.forEach(e=>{
                    let arr = []
                    array.forEach(el =>{
                        if(el[attributeName] == e){
                            arr.push(el)
                        }
                    })

                    returnType == 'array' ? cleanArray.push(arr) : arr = Object.assign(cleanArray,{
                        [e]:arr
                    })
                })
                return cleanArray;
            },

            percent(number,total){
                if(!number || !total){
                    return false
                }
                return `${this.getTwoDecimals((number / total) * 100)}%`
            },

            // sum all the value inside the array base on the attribute given
            sumArraybyAttribute(array = [],attributeName){
                if(array.length == 0 || !attributeName){
                    console.log('error in sumArraybyAttribute function')
                    return 0
                }

                let sum = 0
                array.forEach(e=>{
                    sum += parseInt(e[attributeName])
                })

                if(isNaN(sum) ){
                    return 0
                }
                return sum
            },

            combineObjects(array) {
                var combinedObject = {};

                for (var i = 0; i < array.length; i++) {
                    var obj = array[i];
                    for (var key in obj) {
                        if (obj.hasOwnProperty(key) && obj[key] !== null) {
                        combinedObject[key] = obj[key];
                        }
                    }
                }

                return combinedObject;
            },
            checkImagePath(image_path) {
                return image_path == null
                    ? process.env.VUE_APP_API_HOST + "/images/no-image.png"
                    : process.env.VUE_APP_API_HOST + "/" + image_path
            },

            // checks if the object already exist in array, if does, update the attribute given base on the operator
            // if not, simple push the object to the array
            addOrUpdateToArray(array = [],object = {},attribute = '',opperator = '+',attributeToCompare = 'id'){
                if(!attribute || array.lenght == 0 || attribute == ''){
                    console.log('error in addOrUpdateArray function')
                    return
                }

                let exist_in_array = isObjectInArray(array,object,attributeToCompare)

                if(exist_in_array){

                    array.forEach(e=>{
                        if(object[attributeToCompare] == e[attributeToCompare]){
                            e[attribute] = performOperation(e[attribute],object[attribute],opperator)
                        }
                    })
                }else{
                    array.push(object)
                }

                function performOperation(a, b, operator) {
                    switch (operator) {
                        case "+":
                        return a + b;
                        case "-":
                        return a - b;
                        case "*":
                        return a * b;
                        case "/":
                        return a / b;
                        default:
                        throw new Error("Invalid operator");
                    }
                }

                function isObjectInArray(array, obj, attribute) {
                    let in_array = false
                    array.forEach(e=>{
                        if(e[attribute] == obj[attribute]){
                            in_array = true
                        }
                    })

                    return in_array;
                }

                return array
            },

            toggleDialog(commit,state){
                this.$store.commit(commit,!state)
            },

            async stockChecker(items,warehouse){
                if(items.lenght == 0 || !!warehouse){
                    return nulld
                }

                let data = null

                await this.$store.dispatch('servicePost',{
                    url:'itemStocks',
                    items:items,
                    warehouse:warehouse
                }).then(response =>{
                    data = response.data
                })

                return data
            },
            isStringConvertibleToJSON(inputString) {
                try {
                    const jsonObject = JSON.parse(inputString);
                    return typeof jsonObject === 'object';
                } catch (e) {
                    return false; // Parsing the string as JSON failed
                }
            },
            replaceSpecialCharactersForRouting(fileName) {
                let replacements = {
                '#': '%23',
                };

                // Replace specified characters with their designated replacements
                let modifiedFileName = fileName;
                Object.entries(replacements).forEach(([char, replacement]) => {
                    modifiedFileName = modifiedFileName.replace(char, replacement);
                });

                return modifiedFileName;
            },
            findIndexInArrayObjects(array, property ,keyToFind){
                const pos = array.map(e => e[property]).indexOf(keyToFind);
                return pos;
            },
            getYearRange(mutation = null){
                let range = []
                let start = moment().subtract(5, 'years').year()
                let end = moment().add(5, 'years').year()
                for (let c = start; c <= end; c++) {
                    range.push(c)
                }
                if(mutation){
                    this.$store.commit(mutation, range);
                }
                return range
            },
            upperCaseFirstLetter(str) {
                const arr = str.split(" ");
                for (var i = 0; i < arr.length; i++) {
                    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                }
                str = arr.join(" ");
                return str;
            },
            numberToColumnLetter(number) {
                let columnLetter = '';
                while (number > 0) {
                    const remainder = (number - 1) % 26;
                    columnLetter = String.fromCharCode(65 + remainder) + columnLetter;
                    number = Math.floor((number - 1) / 26);
                }
                // console.log(columnLetter);
                return columnLetter;
            },
            newLine(event, last, array, currentRowIndex, attributeNameToFocus, addRowFunction, vuetifyMoney) {
                let didNewLine = false;
                let particularsContainerRows = document.querySelector('.particulars-container');

                this.indexToBeFocused = currentRowIndex + 1;
                this.attributeToBeFocused= attributeNameToFocus;

                if (currentRowIndex < particularsContainerRows.length - 1) return;

                if (event.key === 'ArrowDown') {
                    if (array) return;
                    event.preventDefault();
                    addRowFunction(attributeNameToFocus);
                    didNewLine = true;
                } else if (event.key === 'Tab' && last) {
                    if (event.shiftKey) return;
                    event.preventDefault();
                    addRowFunction(attributeNameToFocus);
                    didNewLine = true;
                }
                // else if (event.key === '.') {
                //     this.$store.commit('PRECISION', 2)
                // }

                if (!didNewLine) return;

                this.$nextTick(() => {
                    let particularsContainerRows = document.querySelector('.particulars-container').getElementsByClassName('particulars-container-row');
                    let targetRow = particularsContainerRows[particularsContainerRows.length - 1];
                    if (vuetifyMoney) return targetRow.querySelector(`#${attributeNameToFocus}`).querySelector('input').focus();
                    targetRow.querySelector(`#${attributeNameToFocus}`).focus();
                })
            },
            async addImageToWorksheet(workbook, worksheet, item, pos){
                let height = 80;
                let width = 60;
                let aspectRatio = null;

                worksheet.getRow(pos + 1).height = height;

                let image = await new Promise((resolve) => {
                    let img = new Image();
                    img.src = item.base64img;
                    img.onload = () => resolve(img);
                });

                aspectRatio = image.naturalWidth / image.naturalHeight;
                let imageHeight = aspectRatio * width;

                let finalImage = workbook.addImage({
                    base64: item.base64img,
                    extension: 'jpg',
                });

                worksheet.addImage(finalImage, {
                    tl: { col: 0.1, row: pos + 0.1 },
                    br: { col: 1, row: worksheet.rowCount },
                    ext: { width: width, height: height },
                    editAs: 'oneCell',
                });

                // if (item.base64NewItemTag !== null) {
                //     let finalImageNewItem = workbook.addImage({
                //         base64: item.base64NewItemTag,
                //         extension: 'jpg',
                //     });

                //     worksheet.addImage(finalImageNewItem, {
                //         tl: { col: 0, row: pos },
                //         ext: { width: 50, height: 50 },
                //         editAs: 'undefined',
                //     });
                // }

                let row = worksheet.getRow(worksheet.rowCount);
                row.width = width
                if (imageHeight > worksheet.getRow(pos + 1).height) {
                    worksheet.getRow(pos + 1).height = imageHeight;
                }
            },
             distinctArrayOfObjects(array, key) {
                return array.reduce((acc, obj) => {
                    if (!acc.some(item => item[key] === obj[key])) {
                    acc.push(obj);
                    }
                    return acc;
                }, []);
            },
            splitArray(arr, size) {
                const chunks = Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size))
                return chunks
            },
        }

    }
</script>

