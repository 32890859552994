<template>
    <v-main style="background-color: black;">
        <v-container fluid>
            <v-row class="system_toolbar system_toolbar_color d-flex justify-content-between flex-wrap">
                <div>
                    <v-tabs background-color="transparent" hide-slider dark style="margin-left: 10px;">
                        <v-img
                        max-height="50"
                        max-width="190"
                        src="@/assets/ingcoph_logo_alt.png"
                        style="margin-left: -5px"
                        class="logo-img"
                      ></v-img>                  </v-tabs>
                </div>
            </v-row>
        </v-container>
        <v-container fluid>
            <div class="d-flex justify-content-center" style="height: auto;">
                <v-dialog max-width="1000" persistent>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="buttonClicked"  color="orange" icon rounded
                            fab plain  v-bind="attrs" v-on="on">
                            <v-icon size="40" left>
                                mdi-check-all
                            </v-icon>
                            <span class="underline-animation fillup-button-textsize" style="font-family: Evogria; font-size: 1.4rem; font-weight: bold;">
                               Confirm Registration
                            </span>
                        </v-btn>
                    </template>
                </v-dialog>
            </div>
            <template>
                <div>
                    <v-img 
                        src="@/assets/ingco_promo_landingv3.jpg"
                        alt="Placeholder"
                    ></v-img>
                </div>
            </template>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header color="black" class="small-header">
                        <span class="text-center" style="font-family: Evogria; font-size: 0.8rem; font-weight: bold;">
                            Activities
                        </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="black">
                        <v-carousel cycle interval="10000" hide-delimiters id="carousel" height="auto" style="height: auto; width: auto">
							<v-carousel-item v-for="(item, i) in items" :key="i">
								<img :src="item.src" style="height: 800px; max-width: 100%; width: auto; display: block; margin: 0 auto;">
							</v-carousel-item>
						</v-carousel>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <div class="d-flex justify-content-center pa-4" style="height: auto;">
                        <v-btn @click="buttonClicked" color="orange" icon rounded
                            fab plain>
                            <v-icon size="40" left>
                                mdi-draw-pen
                            </v-icon>
                            <span class="underline-animation" style="font-family: Evogria; font-size: 1.4rem; font-weight: bold;">
                                Confirm Registration
                            </span>
                        </v-btn>
            </div>
        </v-container>          
    </v-main>
</template>

<script>
    import Swal from 'sweetalert2';
    export default {
    data() {
        return {
        items: [
            {
            src: require('@/assets/ingcoph_logo.jpg'),
            },
            {
            src: require('@/assets/banner.jpg.png'),
            },
            {
            src: require('@/assets/banner.jpg.png'),
		},
        ],
        currentItem: 0, 
        };
    },
    created() {
        document.title = 'Confirm Registration';
        this.startCarouselAutoAdvance();
    },
    beforeDestroy() {
        this.stopCarouselAutoAdvance();
    },
    methods: {

        buttonClicked() {
    Swal.fire({
        title: 'Please Wait',
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
    });
    Swal.showLoading();

    setTimeout(() => {
        Swal.close();
        setTimeout(() => {
            // this.$router.push('/ConfirmationFillUp');
            this.reloadPage();
        }, 1000); // Wait 1 second after closing Swal
    }, 1000); // Wait 2 seconds before closing Swal
},
reloadPage() {
    window.location.reload();
    },

        startCarouselAutoAdvance() {
        this.carouselTimer = setInterval(() => {
            this.currentItem = (this.currentItem + 1) % this.items.length;
        }, 10000); // Advance every 5 seconds (5000 milliseconds)
        },
        stopCarouselAutoAdvance() {
        clearInterval(this.carouselTimer);
        },
        reset() {
        this.currentItem = 1;
        },
    },
    };
</script>

<style scoped>

    .transparent-btn { background-color: transparent; border: none; }
    .black-font { color: black !important; }
    .slide-enter-active, .slide-leave-active {
        transition: transform 0.5s;
    }
    #ingco-logo-img{
        height: 40px;
    }
    .slide-enter, .slide-leave-to {
        transform: translateX(-6%);
    }
    body {
        overflow: hidden;
    }
    .flex-container {
        display: flex;
        align-items: center;
    }
    .flex-container span {
        margin-right: 10px; 
    }
    .adaptive-text {
        font-size: 14px;
        justify-content: center;
    }
    @media (max-width: 600px) {
        .adaptive-text {
        font-size: 12px; 
        }
    }
    .fillup-button-textsize{
        font-size: 14px;
        justify-content: center;
    }
    @media (max-width: 600px) {
        .fillup-button-textsize {
        font-size: 10px; 
        }
    }
    .small-header {
        padding-top: 1px; /* Adjust as needed */
        padding-bottom: 1px; /* Adjust as needed */
    }
    body {
    background-color: black;
    }
    .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 800px; /* Adjust this value as needed */
    }
</style>

