<template>
	<v-app class="system_body_color" :height="height" style="min-height: 100vh;">
		<div id="app">
			<!-- <NotFoundComponentVue v-if="GET_EVENT_ID === null || GET_EVENT_ID === 0" /> -->
			<router-view/>
		</div>
	</v-app>
</template>
<script>
	import HelloWorld from './components/LandingPage.vue';

	import './assets/css/scrollbar.css';
	import { mapGetters } from 'vuex';
	import NotFoundComponentVue from './components/NotFoundComponent.vue';
	export default {
		components: {
			HelloWorld,
			NotFoundComponentVue
		},
		name: 'App',
		data: () => ({
			sheet: false,
			showHelloWorld: true, 
		}),
		computed: {
			...mapGetters([
				'GET_EVENT_ID'
			]),
			height() {
				switch (this.$vuetify.breakpoint.name) {
					case 'xs': return 220;
					case 'sm': return 400;
					case 'md': return 500;
					case 'lg': return 600;
					case 'xl': return 800;
				}
			},
		},
		async beforeCreate(){
			const searchParams = new URLSearchParams(window.location.search);
			let eventLink = searchParams.get('ec');
			let payloadLink = {
				url: 'checkEventLink',
				eventLink: eventLink
			}
			await this.$store.dispatch('urlPost', payloadLink).then((res)=>{
				if(res == 0) {
					this.$store.commit('EVENT_ID', res)
					if(this.$router.currentRoute.path != '/notFound'){
						this.$router.push('/notFound');
					}
				}else{
					this.$store.commit('EVENT_ID', res)
					let payload = {
						url: 'moduleSettings',
						type: 'Preferences',
						event_id: res
					}
					this.$store.dispatch('urlGet', payload).then((response)=>{
						this.$store.commit('PREFERENCES', response)
						if(response.length > 0){
							response.forEach(x=>{
								if(x.value !== null){
									document.documentElement.style.setProperty(`--${x.parameter}`, x.value);
								}
							})
						}
					})
				}
			})
		},
		mounted(){
		},
		methods: {
			toggleSheet() {
			if (this.$route.path.includes('/confirmation || /raffle || /INGCONight')) {
				this.showHelloWorld = false; 
			} else {
				this.sheet = !this.sheet;
				this.toggleHelloWorld();
			}
			},
			closeSheet() {
				this.sheet = false;
			},
			toggleHelloWorld() {
				this.showHelloWorld = !this.showHelloWorld; 
			},
			async checkCode(){
				
			}
		},
		watch:{
		}
	};
</script>
<style>
	@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Roboto:wght@100&display=swap');
	@import '/src/assets/css/custom.css';

</style>
