import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './components/routes/routes';
import store from './store/modules';
import './registerServiceWorker'

Vue.config.productionTip = false
window.$ = window.jQuery = require('jquery');

Vue.use({
  install: function(Vue, options){
      Vue.prototype.$jQuery = require('jquery'); // you'll have this.$jQuery anywhere in your vue project
      }
  });


new Vue({
  router,
  store,
  jQuery,
  vuetify,
  render: h => h(App)
}).$mount('#app')



