import api from '@/api/api'

export default {

    urlGet(params){
        return api.get(`/api/${params.url}`,{params});
    },
    urlPost(params){
        return api.post(`/api/${params.url}`,params);
    },
    urlPut(params){
        return api.put(`/api/${params.url}/${params.id}`,params);
    },
    urlDelete(params){
        return api.delete(`/api/${params.url}/${params.id }`, {params});
    },
}
