import Vue from "vue";
import VueRouter from "vue-router";
import LandingPage from "../LandingPage.vue";
import Home from "../Header.vue";
import ConfirmRegistrationComponent from "../ConfirmRegistrationComponent.vue";
import ConfirmationLandingPage from "../ConfirmationLandingPage.vue";
import ConfirmRegistrationDetails from "../ConfirmRegistrationDetails.vue";
import NotFoundComponent from "../NotFoundComponent.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/Landing',
    },
    {
        path: '/Landing',
        name: 'LandingPage',
        component: LandingPage,
    },
    {
        path: '/welcome',
        name: 'Home',
        component: Home,
    },
    // {
    //     path: '/ConfirmationFillUp',
    //     name: 'ConfirmRegistrationComponent',
    //     component: ConfirmRegistrationComponent,
    // },
    {
        path: '/ConfirmationHome',
        name: 'ConfirmationLandingPage',
        component: ConfirmationLandingPage,
    },
    {
        path: '/ConfirmationDetails',
        name: 'ConfirmRegistrationDetails',
        component: ConfirmRegistrationDetails,
    },
    {
        path: '/notFound',
        name: 'notFoundPage',
        component: NotFoundComponent,
    },
];

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes,
});

export default router;
