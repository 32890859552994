<template>
    <v-window v-model="carousel_nominee" :touchless="true">
        <v-window-item v-for="(item, i) in items" :key="i">
            <v-carousel cycle interval="10000" hide-delimiters :show-arrows="item.nominee_carousel_images.length > 1 ? true : false" height="auto" >
                <v-carousel-item v-for="(image, index) in item.nominee_carousel_images"  :key="index">
                    <v-card tile height="400" >
                        <v-img 
                            :src="checkImagePath(image)" 
                            height="inherit"
                            width="inherit"
                            lazy-src="@/assets/loading_img.jpg"
                            contain
                        >   
                        </v-img>
                    </v-card>
                </v-carousel-item>
            </v-carousel>   
            <v-list two-line>
                <v-list-item>
                    <v-list-item-content class="pa-0 ma-0">
                        <v-list-item-title class="text-center pt-2">
                           <!-- <v-badge
                                :value="item.selected == 1"
                                color="#f69f1a"
                                content="Voted"
                                offset-x="20"
                                offset-y="0"
                            > -->
                                <span> {{ item.full_name }} </span>
                            <!-- </v-badge> -->
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-center">
                            <span> from {{ item.company_name }} </span>

                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="text-center pa-0" v-if="item.selected == 1 && settings.voting_start_date">
                            <span class="float-right">
                                <v-img :src="'voted.png'" class="pa-0 ma-0" contain height="65" width="65">
                                </v-img>
                            </span>
                            <!-- <span class="badge badge-voted float-right" >{{ 'Voted' }} <v-icon size="20" color="black">mdi-stamper</v-icon></span> -->
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list> 
        </v-window-item>
        <v-row class="ma-1" align="center">
            <v-col class="ma-0 pa-0" cols="3">
                <v-btn @click="prev" class="window_carousel_navigate_button_color window_carousel_navigate_text_color">
                    Prev
                </v-btn>
            </v-col>
            <v-col class="ma-0 pa-0 text-center" cols="6">
                <v-btn @click="voteNominee()" class="window_action_button_color window_action_button_text_color" v-if="(settings.voting_start_date !== null && settings.voting_end_date === null) && checkRemainingVote()">
                    Vote<v-icon>mdi-vote-outline</v-icon>
                </v-btn>
            </v-col>
            <v-col class="ma-0 pa-0 text-end" cols="3">
                <v-btn @click="next" class="window_carousel_navigate_button_color window_carousel_navigate_text_color">
                    Next
                </v-btn>
            </v-col>
        </v-row>
    </v-window>
</template>
<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
export default {
    props:['nomineeType', 'settings'],
    data() {
        return {
            items: [],
            carousel_nominee: 0,
            temp_vote_check: {}
        };
    },
    computed: {
        ...mapGetters([
            'GET_CURRENT_USER',
            'GET_VOTED'
        ])
    },
    created() {
    },
    async mounted() {
        await this.getActivities()
    },
    methods: {
        async getActivities(){
            let payload = {
                url: "getNomineeList",
                type: this.nomineeType,
                voter_id: this.GET_CURRENT_USER,
            }
            await this.$store.dispatch('urlPost', payload).then((res)=>{
                this.items = res.data
            })
        },
        font_size() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return "11"
                case 'sm': return "11"
                case 'md': return "15"
                case 'lg': return "15"
                case 'xl': return "15"
            }
        },
        imageHeight(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '400'
                case 'sm': return '400'
                case 'md': return '300'
                case 'lg': return '300'
                case 'xl': return '300'
            }
        },
        pos(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '38%'
                case 'sm': return '45%'
                case 'md': return '60%'
                case 'lg': return '60%'
                case 'xl': return '60%'
            }
        },
        checkImagePath(image_path) {
            return image_path == null
                ? process.env.VUE_APP_API_HOST + "/images/no-image.png"
                : process.env.VUE_APP_API_HOST + "/" + image_path
        },
        next(){
            this.carousel_nominee = this.carousel_nominee + 1 === this.items.length ? 0 : this.carousel_nominee + 1
        },
        prev(){
            this.carousel_nominee = this.carousel_nominee - 1 < 0 ? this.items.length - 1 : this.carousel_nominee - 1
        },
        voteNominee(){
            let event_participant = this.items.find((x, i) => {
                if(i == this.carousel_nominee){
                    return x;
                }
            });
            Swal.fire({
                title: "Vote",
                text: `Are you sure you want to vote ${event_participant.full_name} ?`,
                icon: 'question',
                showConfirmButton:true,
                confirmButtonText:'Confirm',
                showCancelButton:true,
                showCloseButton:true,
                confirmButtonColor: '397373',
                cancelButtonColor:'grey',
                reverseButtons:true,
            }).then((result)=>{
                if(result.isConfirmed){
                    let event_participant = this.items.find((x, i) => {
                        if(i == this.carousel_nominee){
                            x.selected = 1;
                            return x;
                        }
                    });
                    let payload = {
                        url: 'eventVoteRecord',
                        voter_id: this.GET_CURRENT_USER,
                        nominee_id: event_participant.event_participant_id,
                        event_id: 1,
                        vote_type_id: this.nomineeType == 'mr' ? 'MRSOF' : 'MSSOF'
                    }
                    this.$store.dispatch('urlPost', payload).then((res)=>{
                        this.modifyVote(false);
                        Swal.fire(res.icon.toUpperCase(),res.message,res.icon);
                    }).catch((error)=>{
                        this.modifyVote(true);
                        if (error.response && error.response.status === 500) {
                            Swal.fire({
                                title: "",
                                text: error.response.data.message,
                                icon: 'warning',
                                customClass: {
                                confirmButton: 'orange-button',
                                },
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    location.reload();
                                }
                            });
                        } else {
                            console.error(error);
                        }
                    })
                }
            })

        },
        checkRemainingVote(){
            let hasVoted = this.nomineeType == 'mr' ? this.GET_VOTED.mr_vote_count : this.GET_VOTED.ms_vote_count;
            return hasVoted;
        },
        modifyVote(value){
            let item = this.GET_VOTED;

            if(this.nomineeType == 'mr'){
                item.mr_vote_count = value;
            }else{
                item.ms_vote_count = value;
            }
            this.$store.commit('VOTED', item)
        }
    },
    watch: {
    },
};
</script>
<style scoped>
.badge-voted{
    padding: .3em .6em .3em;
    font-weight: 500;
    background-color: #f69f1a !important;
    color: black !important;
}
</style>
